import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {InterviewService} from "../../services/InterviewServices";
import {InterviewHistoryGET} from "../../types/InterviewService";
import {CenterRippleLoading} from "../loading/CenterRippleLoading";

const InterviewHistory = () => {
    const [interviewsHistory, setInterviewsHistory] = useState<InterviewHistoryGET[]>([]);
    const [loading, setLoading] = useState(true);
    const [actualPage, setActualPage] = useState(0);
    const navigate = useNavigate();
    const loggedUser = localStorage.getItem("loggedUser")!;
    const interviewServices = new InterviewService({token: loggedUser});

    const getInterviewHistory = async () => {
        const data = await interviewServices.GetInterviewsHistory(actualPage);
        setInterviewsHistory(data);
        setLoading(false);
    };

    const handleInterviewClick = (interviewId: number) => {
        navigate(`/interview/${interviewId}`);
    };

    useEffect(() => {
        getInterviewHistory();
    }, [actualPage]);
    if (loading) return CenterRippleLoading("Loading interviews history...");

    if (interviewsHistory.length === 0) return <NoInterviewComponent/>;

    return (
        <div>
            <div>
                <input
                    type="button"
                    onClick={() => navigate("/")}
                    className="btn btn-primary"
                    style={{marginBottom: "1rem"}}
                    value="Back"
                />
            </div>
            <div
                style={{
                    flexFlow: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <h2>Interviews History</h2>
                <div
                    style={{
                        flexFlow: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "normal",
                        minWidth: "150px",
                    }}
                >
                    <input
                        type="button"
                        onClick={() => setActualPage(actualPage - 1)}
                        className="btn btn-primary"
                        style={{marginBottom: "1rem"}}
                        value="<"
                        disabled={actualPage === 0}
                    />
                    <h3>{actualPage}</h3>
                    <input
                        type="button"
                        onClick={() => setActualPage(actualPage + 1)}
                        className="btn btn-primary"
                        style={{marginBottom: "1rem"}}
                        value=">"
                        disabled={interviewsHistory.length < 10}
                    />
                </div>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Finished At</th>
                    <th scope="col">Job Title</th>
                </tr>
                </thead>
                <tbody>
                {Array.isArray(interviewsHistory) && interviewsHistory.map((interview) => (
                    <tr key={interview.id}>
                        <td>
                            <input
                                type="button"
                                onClick={() => handleInterviewClick(interview.id)}
                                value={interview.id}
                                className="btn btn-primary"
                                disabled={interview.status === "completed"}
                            />
                        </td>
                        <td>
                            {interview.status.charAt(0).toUpperCase() +
                                interview.status.slice(1)}
                        </td>
                        <td>{interview.started_at}</td>
                        <td>{interview.finished_at}</td>
                        <td>{interview.job_title}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const NoInterviewComponent = () => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                flexFlow: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <h3>No interviews found :c</h3>
            <br/>
            <input
                type="button"
                className="btn btn-primary"
                value="Let's do an interview!"
                onClick={() => navigate("/interview")}
            />
        </div>
    );
};

export default InterviewHistory;
